<template>
  <v-card flat>
    <v-card-text class="px-0 py-0">
      <v-row>
        <v-col cols="12" v-if="information.status" class="text-center">
          <v-alert color="blue lighten-2" dark>
            {{ information.message }}
          </v-alert>
        </v-col>
        <v-col class="d-flex flex-column justify-space-between align-center">
          <p class="caption text-center">
            {{ $t("leave.request.leave_balance") }}
          </p>
          <p class="title">{{ totalBalance }} {{ $t("global.day") }}</p>
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col class="d-flex flex-column justify-space-between align-center">
          <p class="caption text-center">
            {{ $t("leave.request.leave_request_count") }}
          </p>
          <p class="title">{{ totalRequestLeaveCount }}</p>
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col class="d-flex flex-column justify-space-between align-center">
          <p class="caption text-center">
            {{ $t("leave.request.leave_decreased_count") }}
          </p>
          <p class="title">
            <v-progress-circular v-if="calculating" color="grey" width="2" size="17" indeterminate />
            <span v-else>
              {{ decreaseLeaveCount }}
            </span>
          </p>
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col class="d-flex flex-column justify-space-between align-center">
          <p class="caption text-center">
            {{ $t("leave.request.max_exceed_leave_balance") }}
          </p>
          <p class="title">
            {{ totalMaxExceedLeaveLeaveBalance }} {{ $t("global.day") }}
          </p>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/**
 * @project personal-idenfit
 * @developer Halil Kılıçarslan
 * @description leave-request-information Component Logic
 * @date 18.08.2020
 */
export default {
  name: "leave-request-information",
  props: {
    leaveAccrual: {
      type: Object
    },
    leaveRequestDays: {
      type: Array
    },
    dateRangeCount: Number,
    leaveRequestType: String,
    extraDay: Number,
    extraMinute: Number,
    calculating: Boolean
  },
  data: () => ({
    information: {
      status: false,
      message: null
    }
  }),
  computed: {
    totalRequestLeaveCount() {
      let result = "";
      if (this.leaveRequestType === "HOURLY") {
        const totalMinutes = this.leaveRequestDays.reduce((total, item) => {
          return item.leaveRequestDayType === "ON_LEAVE" ? total + item.lengthInMinutes : total;
        }, 0);
        const formatHours = this.$moment
          .utc()
          .startOf("day")
          .add({ minutes: totalMinutes })
          .format("H:mm");
        result = `${formatHours} ${this.$t("time_duration.hour")}`;
      } else {
        result = `${this.leaveRequestDays.length.toFixed(2)} ${this.$t("global.day")}`;
      }
      return result;
    },
    totalMaxExceedLeaveLeaveBalance() {
      const total = this.leaveAccrual.leavePolicy.leaveType.restriction.maxExceedLeaveBalance || 0;
      return total.toFixed(2);
    },
    totalBalance() {
      let total = this.leaveAccrual
        ? this.leaveAccrual.previousBalance + this.leaveAccrual.leavePolicy.accrualCount - this.leaveAccrual.used
        : 0;
      const fixedTotal = total.toFixed(2);
      return Number(fixedTotal);
    },
    decreaseLeaveCount() {
      let result = "";
      if (this.leaveRequestType === "HOURLY") {
        const totalMinutes = this.leaveRequestDays.reduce((total, item) => {
          return item.leaveRequestDayType === "ON_LEAVE" ? total + item.lengthInMinutes : total;
        }, 0);
        const formatHours = this.$moment
          .utc()
          .startOf("day")
          .add({ minutes: totalMinutes - this.extraMinute })
          .format("H:mm");
        result = `${formatHours} ${this.$t("time_duration.hour")}`;
      } else {
        const totalCount = this.leaveRequestDays.reduce((total, item) => {
          return item.leaveRequestDayType === "ON_LEAVE" ? total + item.durationValue() : total;
        }, 0);
        result = `${(totalCount + this.extraDay).toFixed(2)} ${this.$t("global.day")}`;
      }
      return result;
    }
  },
  methods: {
    totalLeaveBalance() {
      let maxExceedLeaveBalance = this.leaveAccrual.leavePolicy.leaveType.restriction.maxExceedLeaveBalance;
      const totalBalanceDiffLeaveRequestBalance =
        this.totalBalance - this.dateRangeCount * this.leaveAccrual.leavePolicy.leaveType.weight;
      if (this.totalBalance <= 0) {
        if (maxExceedLeaveBalance === null) {
          this.information.message = this.$t("leave.error_messages.total_leave_balance_error1");
          this.information.status = true;
        } else if (totalBalanceDiffLeaveRequestBalance + maxExceedLeaveBalance >= 0) {
          this.information.message = `${this.$t("leave.error_messages.leave_balance_error1")} ${Math.abs(
            totalBalanceDiffLeaveRequestBalance
          )} ${this.$t("leave.error_messages.leave_balance_error2")}`;
          this.information.status = true;
        } else {
          this.information.status = false;
          this.information.message = null;
        }
      } else {
        this.information.status = false;
        this.information.message = null;
      }
    }
  },
  mounted() {
    this.totalLeaveBalance();
  }
};
</script>

<style scoped></style>
